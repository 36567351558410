import { Icon } from "@iconify/react";
import { TextInput, Button, PasswordInput, Text } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { Link, useFetcher, useSearchParams } from "@remix-run/react";
import { useAtom } from "jotai";
import { useEffect, useMemo } from "react";

import { GeneralErrorBoundary } from "~/components/error-boundary";
import InputSelectBranch from "~/components/inputs/input-select-branch";
import Logo from "~/components/layout/logo";
import { requireAuth } from "~/lib/auth";
import { modalAtom } from "~/stores";

import { actionHandler } from "./action";
import { LoginSchema, schema } from "./constants";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const sessionToken = await requireAuth(request);
  if (sessionToken) {
    return redirect("/dashboard");
  }

  return json({});
};

export async function action({ request }: ActionFunctionArgs) {
  return actionHandler(request);
}

export const meta: MetaFunction = () => [{ title: "Login" }];

export default function LoginPage() {
  const form = useForm<LoginSchema>({
    initialValues: {
      branch: "",
      email: "",
      password: "",
    },
    validate: zodResolver(schema),
  });

  const fetcher = useFetcher<typeof action>();

  function signin(values: typeof form.values) {
    const { hasErrors } = form.validate();
    if (!hasErrors) {
      fetcher.submit(
        { ...values },
        {
          method: "post",
          encType: "application/json",
          action: "/auth/login",
        },
      );
    }
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const isModalOpen = useMemo(
    () => searchParams.get("modal") === "error",
    [searchParams],
  );
  const defaultMessage =
    "Masukkan email, kata sandi, dan cabang untuk masuk aplikasi.";
  const modalMessage = useMemo(
    () => searchParams.get("message"),
    [searchParams],
  );

  const [modal, setModal] = useAtom(modalAtom);
  useEffect(() => {
    if (isModalOpen) {
      setModal({
        ...modal,
        isOpen: true,
        illustration: <img src="/error.svg" alt="request otp error" />,
        title: "Gagal Masuk",
        description: modalMessage || defaultMessage,
        confirmText: "Coba Lagi",
        variant: "error",
        onConfirm: () => {
          searchParams.delete("modal");
          searchParams.delete("message");
          setSearchParams(searchParams);
        },
      });
    }
  }, [isModalOpen]);

  return (
    <div className="mx-auto flex flex-col justify-center space-y-6 h-screen w-[350px] lg:w-full">
      <div className="flex flex-col mx-auto text-center w-5/6 lg:w-full">
        <Logo className="w-[144px] mx-auto" />
      </div>
      <div className="flex flex-col justify-center">
        <div className="mx-auto w-full max-w-md px-8">
          <form
            onSubmit={form.onSubmit(signin)}
            className="flex flex-col gap-3"
          >
            <TextInput
              styles={{
                label: {
                  fontWeight: 600,
                },
                root: {
                  width: "100%",
                },
              }}
              label="Email atau Username"
              placeholder="Masukkan email atau username"
              autoComplete="off"
              aria-label="email"
              withAsterisk
              data-cy="email"
              {...form.getInputProps("email")}
              leftSection={<Icon icon="tabler:user" className="h-4 w-4" />}
            />

            <PasswordInput
              styles={{
                label: {
                  fontWeight: 600,
                },
                root: {
                  width: "100%",
                },
              }}
              label="Kata Sandi"
              withAsterisk
              placeholder="Kata sandi (8+ karakter)"
              aria-label="password"
              data-cy="password"
              {...form.getInputProps("password")}
              leftSection={<Icon icon="tabler:lock" className="h-4 w-4" />}
            />
            <InputSelectBranch form={form} name="branch" />

            <Link to="/auth/forgot-password" data-cy="forgot-password">
              <Text c="ihcGreen" fw={600} className="text-right">
                Lupa Kata Sandi?
              </Text>
            </Link>
            <Button
              type="submit"
              size="sm"
              color="ihcGreen"
              fullWidth
              className="mt-3"
              data-cy="submit"
              loading={fetcher.state === "submitting"}
            >
              Masuk
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}
